import { CountryCode } from "../../../settings/countries";
import { PetSex, PetSpecies } from "../../../settings/pet";
import { LeadError } from "../../../utils/error-utils/errors";

export interface LeadValuesUpdatable {
  countryIso?: CountryCode;
  petName?: string;
  petSex?: PetSex | null;
  petBreedKbKey?: string | null;
  petSecondBreedKbKey?: string | null;
  email?: string;
  petSpecies?: PetSpecies | null;
  petBirthDate?: Date | null;
  petDiseases?: string[] | null;
  metaFbc?: string | null;
  metaFbp?: string | null;
}

export interface LeadDTO {
  countryIso: CountryCode;
  firstUtmMedium: string;
  firstUtmSource: string;
  lastUtmMedium: string;
  lastUtmSource: string;
  petName: string;
  petSex: PetSex | null;
  petBreedKbKey: string | null;
  petSecondBreedKbKey: string | null;
  email: string;
  petSpecies: PetSpecies | null;
  petBirthDate: Date | null;
  petBirthDateSetByUser: boolean;
  petDiseases: string[] | null;
  metaFbc?: string | null;
  metaFbp?: string | null;
}

export class Lead implements LeadDTO {
  readonly countryIso: CountryCode;
  readonly firstUtmMedium: string;
  readonly firstUtmSource: string;
  readonly lastUtmMedium: string;
  readonly lastUtmSource: string;
  readonly petName: string;
  readonly petSex: PetSex | null;
  readonly petBreedKbKey: string | null;
  readonly petSecondBreedKbKey: string | null;
  readonly email: string;
  readonly petSpecies: PetSpecies | null;
  readonly petBirthDate: Date | null;
  readonly petBirthDateSetByUser: boolean;
  readonly petDiseases: string[] | null;
  readonly metaFbc?: string;
  readonly metaFbp?: string;

  constructor(attrs: LeadDTO) {
    Object.assign(this, attrs);
  }

  toSnakeCase(): Record<string, unknown> {
    const snakeCaseObj: Record<string, unknown> = {};
    const keys: string[] = Object.keys(this);

    for (const key of keys) {
      const snakeCaseKey: string = key.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);

      snakeCaseObj[snakeCaseKey] = this[key];
    }

    return snakeCaseObj;
  }

  isPetSpeciesValid(): boolean {
    return this.petSpecies !== null && typeof PetSpecies[this.petSpecies] !== "undefined";
  }

  isPetSexValid(): boolean {
    return this.petSex !== null && typeof PetSex[this.petSex] !== "undefined";
  }

  isPetBirthDateValid(): boolean {
    return this.petBirthDate instanceof Date && !isNaN(this.petBirthDate.getTime());
  }

  isPetDiseasesValid(): boolean {
    const isValidContent = (diseases: string[]) => diseases.every((disease) => disease !== "");

    return (
      !this.petDiseases || (Array.isArray(this.petDiseases) && isValidContent(this.petDiseases))
    );
  }

  isPetBreedKbKeyValid(kbKey: string | null): boolean {
    if (!kbKey) {
      return false;
    }

    const regex = /^[a-z0-9,-]{36}$/;

    return regex.test(kbKey);
  }

  isPetSecondBreedKbKeyValid(kbKey: string | null): boolean {
    if (!kbKey) {
      return true;
    }

    const regex = /^[a-z0-9,-]{36}$/;

    return regex.test(kbKey);
  }

  isPetNameValid(): boolean {
    return !!this.petName;
  }

  isEmailValid(): boolean {
    const regex = /^[\w+\-.?]+@[a-z\d-]+(?:\.[a-z]+)*\.[a-z]+$/i;

    return regex.test(this.email);
  }

  ensureLeadToCreateResultIsValid(): void {
    const validations = [
      { isValid: this.isPetSpeciesValid(), attributeName: "Pet species" },
      { isValid: this.isPetSexValid(), attributeName: "Pet sex" },
      { isValid: this.isPetNameValid(), attributeName: "Pet name" },
      { isValid: this.isPetBreedKbKeyValid(this.petBreedKbKey), attributeName: "Pet breed kb key" },
      {
        isValid: this.isPetSecondBreedKbKeyValid(this.petSecondBreedKbKey),
        attributeName: "Pet second breed kb key",
      },
      { isValid: this.isPetBirthDateValid(), attributeName: "Pet birthdate" },
      { isValid: this.isPetDiseasesValid(), attributeName: "Pet diseases" },
      { isValid: this.isEmailValid(), attributeName: "Email" },
    ];

    const invalidAttributes = validations
      .filter((validation) => !validation.isValid)
      .map((validation) => validation.attributeName);

    if (invalidAttributes.length > 0) {
      const errorMessage = `${invalidAttributes.join(", ")} ${
        invalidAttributes.length > 1 ? "are" : "is"
      } required.`;

      throw new LeadError(errorMessage);
    }
  }
}
