import { graphql } from "gatsby";

import OnboardingLayout from "../../organisms/lead/OnboardingLayout";
import Sex from "../../organisms/lead/Sex";

const SexPage = (): JSX.Element => (
  <OnboardingLayout>
    <Sex />
  </OnboardingLayout>
);

export default SexPage;

export const query = graphql`
  query SexPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
