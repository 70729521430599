import cn from "classnames";

import * as styles from "../../core/ui/organisms/footer/Footer.module.scss";

const Footer = (): JSX.Element => {
  return (
    <footer className={cn("or-footer", styles.footer, styles.alternativeFooter)}>
      <section className={styles.disclaimers}>
        <p>Copyright © {new Date().getFullYear()} Barkibu</p>
      </section>
    </footer>
  );
};

export default Footer;
