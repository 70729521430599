import { Events, track } from "../analytics";
import { getPreviousPath, getWindow } from "../browser-features";

const useTrackOnboardingPageViewed = (): void => {
  const previousPath: string | null = getPreviousPath();
  const w: Window | null = getWindow();

  if (previousPath === null || !w) {
    return;
  }

  const checkOnboardingPageViewed = () => {
    const onboardingPageViewed: string | null = w.sessionStorage.getItem("onboardingPageViewed");
    const isFirstView = !onboardingPageViewed;

    if (isFirstView) {
      w.sessionStorage.setItem("onboardingPageViewed", "true");
    }

    return isFirstView;
  };

  track(Events.ONBOARDING_VIEWED_BROWSER, {
    path: w.location.pathname,
    previousPath,
    isFirstOnboardingPageViewed: checkOnboardingPageViewed(),
  });
};

export default useTrackOnboardingPageViewed;
