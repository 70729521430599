import cn from "classnames";
import { ReactNode } from "react";

import Heading, { HeadingLevel } from "../../atoms/heading/Heading";
import * as styles from "./SectionTitle.module.scss";

type SectionTitleProps = {
  pretitle?: ReactNode;
  title: ReactNode;
  subtitle?: ReactNode;
  adoptionClassName?: string;
  headingLevel?: HeadingLevel;
};
const SectionTitle = ({
  pretitle,
  title,
  subtitle,
  adoptionClassName,
  headingLevel = 2,
}: SectionTitleProps): JSX.Element => {
  return (
    <header className={cn("ml-section-title", styles.sectionTitle, adoptionClassName)}>
      {pretitle && <p className="section-pretitle">{pretitle}</p>}
      <Heading level={headingLevel} adoptionClassName={cn(styles.title, "section-title")}>
        {title}
      </Heading>
      {subtitle && <p className={cn(styles.subtitle, "section-subtitle")}>{subtitle}</p>}
    </header>
  );
};

export default SectionTitle;
