import cn from "classnames";
import { Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import { ReactNode } from "react";

import SectionTitle from "../../molecules/section-title/SectionTitle";
import * as styles from "./PageStepForm.module.scss";

export interface PageStepFormDataElement {
  initialValues: {};
  validationSchema?: {};
  validateOnMount?: boolean;
  handleSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void;
  children: (props: FormikProps<FormikValues>) => ReactNode;
}

interface PageStepFormProps {
  title: string;
  subtitle?: ReactNode;
  image?: string;
  formData: PageStepFormDataElement;
  adoptionClassName?: string;
}

const PageStepForm = ({
  title,
  subtitle,
  image,
  formData,
  adoptionClassName,
}: PageStepFormProps): JSX.Element => {
  const { initialValues, validationSchema, validateOnMount, handleSubmit, children } = formData;

  return (
    <section className={cn(styles.pageStepForm, adoptionClassName)}>
      {image && (
        <img className={styles.image} src={image} alt="" width="88" height="88" loading={"lazy"} />
      )}
      <SectionTitle title={title} subtitle={subtitle} />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={validateOnMount}
        onSubmit={handleSubmit}
      >
        {children}
      </Formik>
    </section>
  );
};

export default PageStepForm;
