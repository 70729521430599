import { atom, RecoilState } from "recoil";

import { sessionStorageRecoilSync } from "../../../core/data/session-storage-repository";
import { CountryCode } from "../../../settings/countries";
import { LeadDTO } from "../domain/lead";

export const leadState: RecoilState<LeadDTO> = atom<LeadDTO>({
  key: "lead",
  default: {
    countryIso: CountryCode.ES,
    firstUtmMedium: "",
    firstUtmSource: "",
    lastUtmMedium: "",
    lastUtmSource: "",
    petName: "",
    petSex: null,
    petBreedKbKey: "",
    petSecondBreedKbKey: null,
    email: "",
    petSpecies: null,
    petBirthDate: null,
    petBirthDateSetByUser: true,
    petDiseases: null,
    metaFbc: null,
    metaFbp: null,
  },
  effects: [sessionStorageRecoilSync("lead")],
});
