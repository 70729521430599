import cn from "classnames";
import { Link } from "gatsby";

import { CountryCode } from "../../settings/countries";
import { homePath } from "../../utils/locale-configuration-utils";
import * as styles from "./Navigation.module.scss";

interface Image {
  default: string;
}

const logoSVG: Image = require("../../images/logo/barkibu.svg") as Image;

const logoSVGSrc: string = logoSVG.default;

interface NavigationProps {
  countryCodeIso?: CountryCode;
}

const Navigation = ({ countryCodeIso = CountryCode.ES }: NavigationProps): JSX.Element => {
  return (
    <header
      id="header"
      className={cn(styles.navigation, styles.navigationOnboarding, "or-navigation")}
    >
      <Link to={homePath(countryCodeIso)} className={styles.brand}>
        <img src={logoSVGSrc} alt="Barkibu" width="90px" height="21px" />
      </Link>
    </header>
  );
};

export default Navigation;
